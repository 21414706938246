import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Text,
  Icon,
  Box,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import Header from "../layout/Header";
import { textBlack, yellow } from "../utils/colors";
import CaseStudy from "../partials/CaseStudy";
import video1 from "../assets/video/video1.mp4";
import domesImage from "../assets/images/domes.jpg";
import domes from "../assets/icons/domes.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import { FaQuoteRight } from "react-icons/fa";
import author1 from "../assets/icons/author1.svg";
import lindenberg from "../assets/images/lindenberg.png";
import linderbergLogo from "../assets/icons/lindenberg.svg";
import author2 from "../assets/icons/author2.svg";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/scrollbar";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CaseStudyAlt from "../partials/CaseStudyAlt";
import CaseStudySmall from "../partials/CaseStudySmall";
import Footer from "../layout/Footer";

// Example testimonials data
const featured = [
  {
    id: 1,
    video: video1,
    image: domesImage,
    logo: domes,
    logoSize: "5rem",
    bgColor: "#c699ff",
    text: "Strmaven's strategic approach significantly increased our bookings and refined our operations—exceptional service!",
    firstWord: "",
    secondWord: "25",
    thirdWord: "%",
    specialPadding: "1rem 1rem",
    resultsText: "reduction in costs.",
    author: author1,
  },
  {
    id: 2,
    video: "",
    image: lindenberg,
    logo: linderbergLogo,
    logoSize: "1rem",
    bgColor: "#81bcf9",
    text: "We share a set of values with Strmavens team that includes honesty, openness and integrity. We wanted to work with like-minded people. That’s exactly what we got.",
    firstWord: "",
    secondWord: "28",
    thirdWord: "%",
    specialPadding: "2rem 1.5rem",
    resultsText: "increase in annual revenue.",
    author: author2,
  },
];

// Example testimonials data
const caseStudies = [
  {
    id: 1,
    image: domesImage,
    logo: domes,
    logoSize: "5rem",
    text: "Domes White Coast Milos partnered with Strmaven to enhance their brand identity and streamline operations, aiming to stand out in a competitive market.",
  },
  {
    id: 2,
    image: lindenberg,
    logo: linderbergLogo,
    logoSize: "1rem",
    text: "TheLindenberg engaged Strmaven to rebrand their property and optimize internal systems to improve guest experiences and operational efficiency.",
  },
  {
    id: 3,
    image: domesImage,
    logo: domes,
    logoSize: "5rem",
    text: "Strmaven overhauled the villa interiors, upgraded amenities, and launched a guest satisfaction initiative. A reputation management strategy was also implemented to handle and improve online reviews.",
  },
  {
    id: 4,
    image: lindenberg,
    logo: linderbergLogo,
    logoSize: "1rem",
    text: "Strmaven redesigned the website to improve user experience, implemented a direct booking incentive program, and enhanced pre-arrival communication with guests.",
  },
];

const CaseStudiesPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [progress, setProgress] = useState("0%");
  const [activeIndex, setActiveIndex] = useState(0); // Track active slide index

  useEffect(() => {
    setProgress("0%"); // Set initial progress to 0%
  }, []);

  const handleAutoplayTimeLeft = (swiper, time, progress) => {
    setProgress(`${(1 - progress) * 100}%`); // Reverse the progress
  };

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex); // Update active index when slide changes
    setProgress("0%");
    setTimeout(() => {
      setProgress("100%");
    }, 0);
  };

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />

      <Flex w="100%" p={["0 0 4rem", "0 0 4rem", "0 0 8rem"]}>
        <Flex w="100%" maxW={["100%", "100%", "1280px"]} m="0 auto">
          <Flex
            w="100%"
            p={["8rem 1.5rem 0", "8rem 1.5rem 0", "12rem 0 0"]}
            direction="column"
          >
            <Heading
              fontSize={["2.6rem", "2.6rem", "6rem"]}
              color={textBlack}
              fontWeight="400"
              lineHeight="1.1"
              w={["100%", "100%", "initial"]}
              mr="1rem"
            >
              A long list of
              <br /> Happy Clients
            </Heading>

            <Flex
              w="100%"
              direction="column"
              m={["3rem 0 0", "3rem 0 0", "8rem 0 0"]}
            >
              <Box w="100%">
                <Flex w="100%" h="1px" bg={textBlack} />
                <Flex
                  w={progress}
                  h="2px"
                  bg={textBlack}
                  //   transition="width 0.9s ease"
                />
              </Box>
              <Flex w="100%" align="center" justify="space-between" m="1rem 0">
                <Text fontSize="1.125rem" fontWeight="500" lineHeight="1.5">
                  Featured
                </Text>
                <Flex align="center" justify="flex-end" gap="0.25rem">
                  {featured.map((testimonial, index) => (
                    <Flex
                      key={testimonial.id}
                      w="1.25rem"
                      h="1.25rem"
                      border={`1px solid ${textBlack}`}
                      bg={activeIndex === index ? textBlack : "transparent"}
                      borderRadius="50%"
                      align="center"
                      justify="center"
                    />
                  ))}
                </Flex>
              </Flex>

              <Swiper
                spaceBetween={30}
                slidesPerView={isMobile ? 1 : 1}
                loop={true}
                speed={900}
                centeredSlides={true}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                onAutoplayTimeLeft={handleAutoplayTimeLeft}
                onSlideChange={handleSlideChange}
                modules={[Autoplay, Pagination, Navigation]}
                className="case-studies-swiper"
              >
                {featured.map((testimonial) => (
                  <SwiperSlide key={testimonial.id}>
                    <CaseStudy
                      video={testimonial.video}
                      image={testimonial.image}
                      logo={testimonial.logo}
                      logoSize={testimonial.logoSize}
                      bgColor={testimonial.bgColor}
                      testimonial={testimonial.text}
                      firstWord={testimonial.firstWord}
                      secondWord={testimonial.secondWord}
                      thirdWord={testimonial.thirdWord}
                      specialPadding={testimonial.specialPadding}
                      resultsText={testimonial.resultsText}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Flex>

            <Flex w="100%" direction="column" m="3.5rem 0 0">
              <Box w="100%">
                <Flex w="100%" h="3px" bg={textBlack} />
              </Box>
              <Flex w="100%" align="center" justify="space-between" m="1rem 0">
                <Text fontSize="1.125rem" fontWeight="500" lineHeight="1.5">
                  Must reads
                </Text>
              </Flex>

              <Flex w="100%">
                <Grid templateColumns={["1fr", "1fr", "1fr 1fr"]} gap="1.25rem">
                  {caseStudies.map((testimonial) => (
                    <GridItem>
                      <CaseStudySmall
                        image={testimonial.image}
                        logo={testimonial.logo}
                        logoSize={testimonial.logoSize}
                        testimonial={testimonial.text}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default CaseStudiesPage;
