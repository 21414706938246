import React, { useState } from "react";
import { Box, Flex, IconButton, Image, Text, Icon } from "@chakra-ui/react";
import { backgroundLight, textBlack, white } from "../utils/colors";
import { FaArrowRightLong } from "react-icons/fa6";

const CaseStudySmall = ({
  logo,
  logoSize,
  image,

  testimonial,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Flex
      w="100%"
      justify="space-between"
      //   gap="1.5rem"
      //   mb="1.5rem"
      minH={["100%", "100%", "300px"]}
      maxH={["100%", "100%", "300px"]}
      h="100%"
      border={`1px solid rgba(0,0,0,0.15)`}
      borderRadius="1.5rem"
      overflow="hidden"
      role="group"
      transition="all 0.2s"
      direction={["column", "column", "row"]}
      _hover={{
        border: `3px solid ${textBlack}`,
      }}
    >
      <Box
        w={["100%", "100%", "50%"]}
        h="100%"
        position="relative"
        overflow="hidden"
        role="group"
      >
        <Flex
          position="absolute"
          top="1rem"
          left="1rem"
          p="0.25rem 0.5rem"
          borderRadius="1.5rem"
          color="rgba(0, 0, 0, .5)"
          backdropFilter="blur(7.5px)"
          background="rgba(255, 255, 255, .5)"
          opacity="1"
          fontWeight="500"
          fontSize="xs"
          textTransform="uppercase"
          zIndex="3"
          _groupHover={{
            bg: textBlack,
            color: white,
          }}
        >
          Case Study
        </Flex>

        <Image
          src={image}
          w="100%"
          h="100%"
          objectFit="cover"
          transition="all 0.3s"
          _groupHover={{
            transform: "scale(1.05)",
          }}
        />

        <Image
          src={logo}
          alt="Domes"
          h={`${logoSize} !important`}
          w={`auto !important`}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1"
        />
      </Box>

      <Flex
        w={["100%", "100%", "50%"]}
        bg={white}
        // borderRadius="1.5rem"
        direction="column"
        align="flex-start"
        justify="space-between"
        // minH="500px"
        p={["1rem 1rem 1.5rem", "1rem 1rem 1.5rem", "1rem"]}
        textAlign="left"
        position="relative"
      >
        <Text
          fontSize={["1.125rem", "1.125rem", "1.25rem"]}
          fontWeight="500"
          lineHeight="1.2"
          mr={["10%", "10%", "20%"]}
        >
          {testimonial}
        </Text>

        <Box
          position={["relative", "relative", "absolute"]}
          bottom={["initial", "initial", "1rem"]}
          right={["initial", "initial", "1rem"]}
          mt={["1rem", "1rem", "0"]}
        >
          <IconButton
            size="lg"
            bg={[white, white, white]}
            borderRadius="10rem"
            //   border={`1px solid ${textBlack}`}
            _groupHover={{
              bg: textBlack,
              color: white,
              border: `1px solid #1e50ff`,
              cursor: "pointer",
            }}
          >
            <Icon as={FaArrowRightLong} fontWeight="300" fontSize="0.75rem" />
          </IconButton>
        </Box>
      </Flex>
    </Flex>
  );
};

export default CaseStudySmall;
