import React from "react";
import {
  Flex,
  Heading,
  Image,
  Text,
  Icon,
  Button,
  IconButton,
  Box,
} from "@chakra-ui/react";
import {
  white,
  textBlack,
  primary,
  textPrimary,
  secondary,
  green,
  yellow,
} from "../utils/colors";
// import main3 from "../assets/images/main3.jpg";
// import TestimonialSwiper from "../components/partials/TestimonialSwiper";
// import mobilemain from "../assets/images/mobilemain.jpg";
import { useMediaQuery } from "react-responsive";
// import map from "../assets/icons/map.svg";
import { FaAirbnb, FaPlus } from "react-icons/fa";
import airbnb from "../assets/icons/airbnblogo.svg";
import map2 from "../assets/icons/map2.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import video1 from "../assets/video/video1.mp4";
import domeswhite from "../assets/icons/domeswhite.svg";
import domes from "../assets/icons/domes.svg";
import CaseStudy from "../partials/CaseStudy";
import domesImage from "../assets/images/domes.jpg";
import lindenberg from "../assets/images/lindenberg.png";
import linderbergLogo from "../assets/icons/lindenberg.svg";
import linderbergLostLogo from "../assets/icons/lindenberg-lost.svg";
import author1 from "../assets/icons/author1.svg";
import author2 from "../assets/icons/author2.svg";
import { useNavigate } from "react-router-dom";

const CaseStudies = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  console.log("isMobile", isMobile);

  // console.log("containerwidth", window.innerWidth);

  return (
    <Flex
      // minH="100vh"
      bg={white}
      w="100%"
      align-="center"
      justify="center"
      p={["5rem 1.5rem 0", "5rem 1.5rem 0", "8rem 0rem 0"]}
      direction="column"
    >
      <Flex
        w="100%"
        maxW={["100%", "100%", "1280px"]}
        m="0 auto"
        direction="column"
      >
        <Flex direction="column" w={["100%", "100%", "100%"]}>
          <Heading
            fontSize={["2rem", "2rem", "2.875rem"]}
            color={textBlack}
            fontWeight="400"
            textAlign={["left", "left", "left"]}
            maxW={["100%", "100%", "60%"]}
            m={["0 ", "0 ", "0"]}
            lineHeight="1.2"
          >
            We pride ourselves on delivering above and beyond for all our
            clients.
          </Heading>

          <Flex align="center" justify="flex-start" m={["2rem 0 0"]}>
            <Button
              bg={white}
              color={textBlack}
              variant="outline"
              size="lg"
              h="4rem"
              pl="0.25rem"
              pr="2rem"
              borderRadius="10rem"
              border={`1px solid ${textBlack}`}
              fontWeight="500"
              role="group"
              onClick={() => {
                navigate("/case-studies");
              }}
              _hover={{
                bg: "#1e50ff",
                color: white,
                border: `1px solid #1e50ff`,
                cursor: "pointer",
              }}
            >
              <Button
                size="lg"
                w="3rem"
                bg={white}
                borderRadius="10rem"
                border={`1px solid ${textBlack}`}
                mr="1rem"
                position="relative"
                overflow="hidden"
                _groupHover={{
                  border: `1px solid ${white}`,
                }}
              >
                <Icon
                  as={FaArrowRightLong}
                  fontWeight="300"
                  fontSize="0.75rem"
                  position="absolute"
                  top="50%"
                  left="-25%"
                  transform="translate(-50%, -50%)"
                  transition="all 0.2s"
                  _groupHover={{
                    left: "50%",
                  }}
                />
                <Icon
                  as={FaArrowRightLong}
                  fontWeight="300"
                  fontSize="0.75rem"
                  position="absolute"
                  top="50%"
                  left="25%"
                  transform="translate(50%, -50%)"
                  transition="all 0.2s"
                  _groupHover={{
                    left: "100%",
                  }}
                />
              </Button>
              Case Studies
            </Button>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          direction="column"
          m={["3rem 0 0", "3rem 0 0", "8rem 0 0"]}
        >
          <CaseStudy
            video={video1}
            image={domesImage}
            logo={domes}
            logoSize={isMobile ? "4rem" : "5rem"}
            bgColor="#c699ff"
            testimonial="“Strmaven's strategic approach significantly increased our bookings and refined our operations—exceptional service!“"
            firstWord=""
            secondWord="25"
            thirdWord="%"
            author={author1}
            specialPadding="1rem 1rem"
            resultsText="reduction in costs."
          />

          <CaseStudy
            // video={video1}
            image={lindenberg}
            logo={linderbergLogo}
            logoSize="1rem"
            bgColor="#81bcf9"
            testimonial="“We share a set of values with Strmavens team that includes honesty, openness and integrity. We wanted to work with like-minded people. That’s exactly what we got.“"
            firstWord=""
            secondWord="28"
            thirdWord="%"
            author={author2}
            specialPadding="2rem 1.5rem"
            resultsText="increase in annual revenue."
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CaseStudies;
