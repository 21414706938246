import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  VStack,
  HStack,
  Link,
  Image,
  Heading,
  Button,
  Icon,
} from "@chakra-ui/react";
import { Link as LinkAlt } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTelegramPlane,
  FaEnvelope,
  FaLinkedinIn,
  FaFacebookF,
} from "react-icons/fa";
import ratingrescue from "../assets/icons/ratingrescue.svg";
import {
  primary,
  secondary,
  textBlack,
  textPrimary,
  white,
  yellow,
} from "../utils/colors";
import strmavenlogosecondary from "../assets/icons/strmavenlogo-secondary.svg";
import { FaArrowRightLong, FaE, FaXTwitter } from "react-icons/fa6";
import { shade } from "polished";
import team from "../assets/images/team.jpg";
import member1 from "../assets/images/team/1.webp";
import member2 from "../assets/images/team/2.webp";
import member3 from "../assets/images/team/3.webp";
import member4 from "../assets/images/team/4.webp";
import member5 from "../assets/images/team/5.webp";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box w="100%" p="0rem">
      <Box bg={textBlack} w="100%" borderRadius="0rem">
        <Flex
          direction="column"
          maxW={["100%", "100%", "1280px"]}
          justify="space-between"
          m="0 auto"
          p={["3rem 1.5rem 1rem", "3rem 1.5rem 1rem", "3rem 0 3rem"]}
        >
          <Flex
            w="100%"
            align="flex-start"
            justify="space-between"
            m="0"
            textAlign="left"
            direction={["column", "column", "row"]}
          >
            <Flex direction="column" w={["100%", "100%", "55%"]}>
              <Text>
                <Flex
                  display="inline-flex"
                  align="center"
                  w={["100%", "100%", "initial"]}
                  position="relative"
                  top="0.75rem"
                  mr="0.5rem"
                  p={["0rem 0 1rem", "0rem 0 1rem", "0"]}
                >
                  <Flex
                    display="inline-flex"
                    w={["3.5rem", "3.5rem", "3.5rem"]}
                    h="3.5rem"
                    borderRadius="10rem"
                    overflow="hidden"
                    flexShrink="0"
                    border={`1px solid ${white}`}
                  >
                    <Image src={member1} w="100%" h="100%" objectFit="cover" />
                  </Flex>
                  <Flex
                    display="inline-flex"
                    w="3.5rem"
                    h="3.5rem"
                    borderRadius="10rem"
                    overflow="hidden"
                    flexShrink="0"
                    border={`1px solid ${white}`}
                    ml="-1rem"
                  >
                    <Image src={member2} w="100%" h="100%" objectFit="cover" />
                  </Flex>
                  <Flex
                    display="inline-flex"
                    w="3.5rem"
                    h="3.5rem"
                    borderRadius="10rem"
                    overflow="hidden"
                    flexShrink="0"
                    border={`1px solid ${white}`}
                    ml="-1rem"
                  >
                    <Image src={member3} w="100%" h="100%" objectFit="cover" />
                  </Flex>
                </Flex>

                <Text
                  as="span"
                  fontSize={["2rem", "2rem", "2.875rem"]}
                  color={white}
                  fontWeight="400"
                  // maxW={["100%", "100%", "50%"]}
                  // m="0 0 3rem"
                >
                  Connect with our expert team and
                </Text>
                <Flex
                  display={["none", "none", "inline-flex"]}
                  align="center"
                  position="relative"
                  top="0.75rem"
                  mx="0.5rem"
                >
                  <Flex
                    display="inline-flex"
                    w="3.5rem"
                    h="3.5rem"
                    borderRadius="10rem"
                    overflow="hidden"
                    flexShrink="0"
                    border={`1px solid ${white}`}
                  >
                    <Image src={member4} w="100%" h="100%" objectFit="cover" />
                  </Flex>
                  <Flex
                    display="inline-flex"
                    w="3.5rem"
                    h="3.5rem"
                    borderRadius="10rem"
                    overflow="hidden"
                    flexShrink="0"
                    border={`1px solid ${white}`}
                    ml="-1rem"
                  >
                    <Image src={member5} w="100%" h="100%" objectFit="cover" />
                  </Flex>
                </Flex>
                <Text
                  as="span"
                  fontSize={["2rem", "2rem", "2.875rem"]}
                  color={white}
                  fontWeight="400"
                  // maxW={["100%", "100%", "50%"]}
                  // m="0 0 3rem"
                >
                  let’s achieve something extraordinary together.
                </Text>
              </Text>

              <Flex align="center" justify="flex-start" m="3rem 0 0">
                <LinkAlt to="mailto:hello@strmaven.com">
                  <Button
                    bg="transparent"
                    color={white}
                    variant="outline"
                    size="lg"
                    h="3.5rem"
                    pl="0.25rem"
                    pr="2rem"
                    borderRadius="10rem"
                    border={`1px solid ${white}`}
                    fontWeight="500"
                    role="group"
                    // onClick={() => {
                    //   navigate("/contact");
                    // }}
                    _hover={{
                      bg: "#1e50ff",
                      color: white,
                      border: `1px solid #1e50ff`,
                      cursor: "pointer",
                    }}
                  >
                    <Button
                      size="lg"
                      w="3rem"
                      bg="transparent"
                      borderRadius="10rem"
                      border={`1px solid ${white}`}
                      mr="1rem"
                      position="relative"
                      overflow="hidden"
                      _groupHover={{
                        bg: white,
                        border: `1px solid #1e50ff`,
                      }}
                    >
                      <Icon
                        as={FaArrowRightLong}
                        fontWeight="300"
                        fontSize="0.75rem"
                        position="absolute"
                        top="50%"
                        left="-25%"
                        transform="translate(-50%, -50%)"
                        transition="all 0.2s"
                        _groupHover={{
                          left: "50%",
                        }}
                      />
                      <Icon
                        as={FaArrowRightLong}
                        fontWeight="300"
                        fontSize="0.75rem"
                        position="absolute"
                        top="50%"
                        left="25%"
                        transform="translate(50%, -50%)"
                        transition="all 0.2s"
                        color={white}
                        _groupHover={{
                          left: "100%",
                        }}
                      />
                    </Button>
                    Get in touch
                  </Button>
                </LinkAlt>
              </Flex>
            </Flex>

            <Flex w={["100%", "100%", "45%"]}>
              <Flex
                direction="column"
                w="100%"
                align={["center", "center", "flex-end"]}
              >
                <Flex direction="column" align="flex-start">
                  <Image
                    src={team}
                    alt="Strmaven"
                    w={["100%", "100%", "300px"]}
                    borderRadius="1.5rem"
                    m={["3rem auto 1rem", "3rem auto 1rem", "0 0 1rem"]}
                  />
                  <Text
                    color={white}
                    fontSize="1.125rem"
                    fontWeight="500"
                    m="0 0 1rem"
                  >
                    Careers
                  </Text>
                  <Text
                    color={white}
                    fontSize="1rem"
                    fontWeight="400"
                    maxW="300px"
                  >
                    Join the leading consultants in the short-term rental
                    industry and elevate your career with the best in the field.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            align="center"
            justify={["center", "center", "space-between"]}
            w="100%"
            direction={["column-reverse", "column-reverse", "row"]}
            borderTop={`1px solid rgba(255,255,255,.15)`}
            mt={["3rem", "3rem", "5rem"]}
          >
            <Flex w={["100%", "100%", "50%"]} gap="1.5rem">
              <Link href="/terms-of-business" target="_blank">
                <Text
                  color={white}
                  fontSize="0.875rem"
                  fontWeight="400"
                  p="1rem 0"
                >
                  Terms of Business
                </Text>
              </Link>

              <Link href="/privacy-policy" target="_blank">
                <Text
                  color={white}
                  fontSize="0.875rem"
                  fontWeight="400"
                  p="1rem 0"
                >
                  Privacy Policy
                </Text>
              </Link>

              <Link href="GDPR Policy" target="_blank">
                <Text
                  color={white}
                  fontSize="0.875rem"
                  fontWeight="400"
                  p="1rem 0"
                >
                  GDPR Policy
                </Text>
              </Link>
            </Flex>

            <Flex
              align={["center", "center", "flex-start"]}
              direction="column"
              m={["1.5rem 0 1rem", "1.5rem 0 1rem", "initial"]}
            >
              <Flex
                gap="1rem"
                justify={["center", "center", "flex-start"]}
                w={["100%", "100%", "initial"]}
              >
                <Link
                  href="https://www.linkedin.com/company/str-maven/"
                  target="_blank"
                >
                  <IconButton
                    as={Link}
                    size="sm"
                    target="_blank"
                    aria-label="Linkedin"
                    icon={<FaLinkedinIn />}
                    bg="#333"
                    color={white}
                    fontSize="1rem"
                    _hover={{
                      textDecoration: "none",
                      bg: white,
                      color: textBlack,
                    }}
                  />
                </Link>

                <Link href="https://instagram.com/str_maven" target="_blank">
                  <IconButton
                    size="sm"
                    target="_blank"
                    aria-label="Instagram"
                    icon={<FaInstagram />}
                    bg="#333"
                    color={white}
                    fontSize="1rem"
                    _hover={{
                      textDecoration: "none",
                      bg: white,
                      color: textBlack,
                    }}
                  />
                </Link>

                <Link href="https://www.facebook.com/strmaven" target="_blank">
                  <IconButton
                    size="sm"
                    target="_blank"
                    aria-label="Facebook"
                    icon={<FaFacebookF />}
                    bg="#333"
                    color={white}
                    fontSize="1rem"
                    _hover={{
                      textDecoration: "none",
                      bg: white,
                      color: textBlack,
                    }}
                  />
                </Link>
                {/* <IconButton
                  as={Link}
                  size="sm"
                  target="_blank"
                  aria-label="Twitter/X"
                  icon={<FaXTwitter />}
                  bg="#333"
                  color={white}
                  fontSize="1rem"
                /> */}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* <Flex direction="column" p="0rem 0">
          <Divider borderColor="gray.600" />
          <Text textAlign="center" fontSize="sm" color="gray.500" p="3rem 0 ">
            &copy; {new Date().getFullYear()} Company Name. All rights reserved.
          </Text>
        </Flex> */}
      </Box>
    </Box>
  );
};

export default Footer;
