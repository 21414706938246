import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import Header from "../layout/Header";
import Hero from "../sections/Hero";
import Services from "../sections/Services";
import Footer from "../layout/Footer";
import CaseStudies from "../sections/CaseStudies";
import About from "../sections/About";
import Numbers from "../sections/Numbers";
import { FaArrowRightLong } from "react-icons/fa6";
import { textBlack, white } from "../utils/colors";
import { AnimatePresence } from "framer-motion";
import about from "../assets/images/about.jpg";

const CareerPage = () => {
  const heroRef = useRef(null); // Create a ref for the Hero section
  const BenefitsRef = useRef(null); // Create a ref for the Benefits section
  const howItWorksRef = useRef(null); // Create a ref for the How It Works section
  const faqRef = useRef(null); // Create a ref for the FAQ section
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBenefits = () => {
    BenefitsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToHowItWorks = () => {
    howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFaq = () => {
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header
        scrollToHowItWorks={scrollToHowItWorks}
        scrollToFaq={scrollToFaq}
        scrollToHero={scrollToHero}
        scrollToBenefits={scrollToBenefits}
      />

      {/* // MODAL START */}
      {/* <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} /> */}
      {/* // MODAL END */}

      <Flex w="100%" direction="column" position="relative">
        <Flex
          w="100%"
          h="100%"
          minH="90vh"
          maxH="100%"
          align="center"
          justify="flex-start"
          position="relative"
          zIndex={9}
          p={["0 1.5rem 4rem", "0 1.5rem 4rem", "0 0 4rem"]}
          // bg={yellow}
          bg={white}
          direction="column"
        >
          <Flex
            direction="column"
            w="100%"
            maxW={["100%", "100%", "1280px"]}
            margin="0 auto"
            textAlign="left"
            p={["10rem 0rem 0", "10rem 0rem 0", "10rem 0 0"]}
          >
            <Flex
              w="100%"
              justify="center"
              align="flex-start"
              direction="column"
              textAlign="left"
            >
              <Flex
                direction="column"
                align="flex-start"
                maxW={["100%", "100%", "100%"]}
              >
                <Flex>
                  <Heading
                    fontSize={["5rem", "5rem", "6rem"]}
                    color={textBlack}
                    fontWeight="400"
                    lineHeight="1.1"
                    w={["100%", "100%", "initial"]}
                    mr="1rem"
                  >
                    Work with the
                    <br />
                    World’s Very Best
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex w="100%" p="5rem 0 0">
            <Image src={about} w="100%" h="100%" objectFit="cover" />
          </Flex>

          <Flex
            w="100%"
            maxW={["100%", "100%", "1280px"]}
            m="0 auto"
            direction="column"
          >
            <Flex direction="column" w="100%" p="6rem 0 0">
              <Heading
                fontSize={["2rem", "2rem", "2.75rem"]}
                color={textBlack}
                fontWeight="400"
                textAlign={["left", "left", "left"]}
                maxW={["100%", "100%", "100%"]}
                m={["0 ", "0 ", "0 auto"]}
              >
                At STR Maven, our exceptional consultancy team tackles even the
                most challenging projects, delivering outstanding results for
                our clients. With strategic thinking, a pragmatic approach, and
                swift execution, we consistently achieve success.
              </Heading>
            </Flex>
          </Flex>

          <About />
        </Flex>
      </Flex>

      <Footer />
    </>
  );
};

export default CareerPage;
