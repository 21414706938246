import React, { useRef } from "react";
import { Flex, Heading, Stack, Text, Image, Box } from "@chakra-ui/react";
import { textBlack } from "../utils/colors";
import about1 from "../assets/icons/about1.svg";
import {
  motion,
  useScroll,
  useTransform,
  useViewportScroll,
  useInView,
} from "framer-motion";

const MotionImage = motion(Image); // Motion component for the image

const About = () => {
  const { scrollY } = useViewportScroll();
  const blueSectionRef = useRef(null);
  const isInView = useInView(blueSectionRef, { once: false });

  // Get the height of the blue section to know when to stop the animation
  const { height: blueSectionHeight } =
    blueSectionRef?.current?.getBoundingClientRect?.() || {};

  // Adjust the image position when the section is in view
  const yTransform = useTransform(scrollY, (value) => {
    const top = blueSectionRef?.current?.getBoundingClientRect()?.top || 0;
    const bottom =
      blueSectionRef?.current?.getBoundingClientRect()?.bottom || 0;

    // Move the image down by 100px while the blue section is in view, stop when it reaches bottom
    if (top <= 0 && bottom > 0) {
      return Math.min(800, blueSectionHeight - bottom); // Y position for image movement
    }

    return 0; // Default Y position
  });

  return (
    <>
      <Flex
        w="100%"
        maxW={["100%", "100%", "1280px"]}
        m="0 auto"
        direction="column"
      >
        <Flex
          w="100%"
          align="center"
          justify="space-between"
          m={["3rem 0 0", "3rem 0 0", "6rem 0 0"]}
          overflow="hidden"
          p={["0 1.5rem 0", "0 1.5rem 0", "0"]}
        >
          <Flex
            w={["100%", "100%", "50%"]}
            display={["none", "none", "flex"]}
          ></Flex>
          <Flex w={["100%", "100%", "50%"]}>
            <Heading
              fontSize={["2rem", "2rem", "2.875rem"]}
              fontWeight="400"
              lineHeight={["1.2", "1.2", "1.1"]}
              letterSpacing=" -.03em"
            >
              For over a decade, businesses have relied on us for innovative,
              independent solutions that drive sustainable success and align
              with their goals. Excellence is at the core of everything we do.
            </Heading>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        ref={blueSectionRef}
        w="100%"
        maxW={["100%", "100%", "1280px"]}
        m="0 auto"
        direction="column"
        // bg="blue"
      >
        <Flex
          w="100%"
          align="flex-start"
          justify="space-between"
          direction={["column", "column", "row"]}
        >
          <Flex w={["100%", "100%", "50%"]} h="100%">
            <Box h="100%" p="4.5rem 0 0" display={["none", "none", "block"]}>
              <MotionImage
                src={about1}
                style={{ y: yTransform }} // Apply Y transformation to image
              />
            </Box>
          </Flex>
          <Flex
            w={["100%", "100%", "50%"]}
            direction="column"
            p={["0rem 1.5rem 0", "0rem 1.5rem 0", "0"]}
          >
            <Stack
              spacing="1rem"
              borderTop={`1px solid rgba(0,0,0,0.15)`}
              mt={["3rem", "3rem", "6rem"]}
            >
              <Text
                fontSize={["2.5rem", "2.5rem", "2.875rem"]}
                fontWeight="400"
                m="2rem 0 0"
                lineHeight="1.1"
              >
                Our Mission
              </Text>
              <Text fontSize="1.125rem" fontWeight="500" m="0" lineHeight="1.5">
                To empower short-term rental businesses and individual hosts by
                providing comprehensive, innovative, and virtual solutions that
                enhance their operational efficiency, maximize bookings, reduce
                costs, and elevate their market presence. Through expert-driven
                strategies in property listing optimization, social media
                management, branding, pricing, upselling, accounting, and
                reputation management, we strive to be the trusted partner that
                drives sustainable growth and success for our clients.
              </Text>
            </Stack>

            <Stack
              spacing="1rem"
              borderTop={`1px solid rgba(0,0,0,0.15)`}
              mt={["6rem", "6rem", "12rem"]}
            >
              <Text
                fontSize={["2.5rem", "2.5rem", "2.875rem"]}
                fontWeight="400"
                m="2rem 0 0"
                lineHeight="1.1"
              >
                Our Vision
              </Text>
              <Text fontSize="1.125rem" fontWeight="500" m="0" lineHeight="1.5">
                To be the leading virtual agency for short-term rental
                businesses worldwide, recognized for our dedication to
                transforming property management through cutting-edge digital
                solutions, exceptional customer service, and a relentless
                commitment to our clients' success. We envision a future where
                every short-term rental operation is empowered with the tools
                and insights needed to achieve unparalleled excellence and
                profitability.
              </Text>
            </Stack>

            <Stack
              spacing="1rem"
              borderTop={`1px solid rgba(0,0,0,0.15)`}
              mt={["6rem", "6rem", "12rem"]}
              mb={["6rem", "6rem", "12rem"]}
            >
              <Text
                fontSize={["2.5rem", "2.5rem", "2.875rem"]}
                fontWeight="400"
                m="2rem 0 0"
                lineHeight="1.1"
              >
                Our Values
              </Text>
              <Text fontSize="1.125rem" fontWeight="500" m="0" lineHeight="1.5">
                No home grown consultants here…our team all have industry
                experience, having held IT leadership positions in industry for
                many years. We recognise the importance of being with you
                throughout the whole lifecycle of concept to benefit realisation
                and ensure that the same people who talk to you upfront see the
                project through to fruition. As independent market experts, we
                ensure that our advice is tailored exclusively to benefit you.
                We are emotionally invested in your success, by your success,
                blending advanced business strategies with cutting-edge digital
                solutions.
              </Text>
            </Stack>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default About;
