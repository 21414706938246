export const black = "#000000";
export const white = "#ffffff";
export const primaryDark = "#0B0B0B";
export const secondaryDark = "#38302E";
export const primaryLight = "#FFFBF5";
export const secondaryLight = "#ffffff";
export const primaryMid = "#F8F0E5";
export const secondaryMid = "#433E49";

export const borderDark = "#635653";
export const alternativeLight = "#b6ada2";

export const primary = "#FF7C36";
export const secondary = "#05D2C3";
export const textPrimary = "#11124a";
export const textSecondary = "#555555";
export const textBlack = "#0D0D0D";
export const yellow = "#F6DB73";
export const green = "#5ECCA0";

export const backgroundLight = "#F8F0E5";
