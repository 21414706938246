import React from "react";
import ReactGA from "react-ga4";
// import Header from "./components/layout/Header";
// import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import "./index.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Hotjar from "@hotjar/browser";
import CaseStudiesPage from "./pages/CaseStudiesPage";
import AboutPage from "./pages/AboutPage";
import CareerPage from "./pages/CareerPage";
import ContactPage from "./pages/ContactPage";

// ReactGA.initialize("G-7ZPW3JQNWX");

// const siteId = 5073315;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/case-studies" element={<CaseStudiesPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/careers" element={<CareerPage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            {/* <Route exact path="/" element={<Test2 />} /> */}
          </Routes>
        </>
      </Router>
    </ChakraProvider>
  );
};

export default App;
