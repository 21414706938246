import React, { useState } from "react";
import { Box, Flex, IconButton, Image, Text, Icon } from "@chakra-ui/react";
import { white } from "../utils/colors";
import { FaArrowRightLong } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

const CaseStudy = ({
  video,
  logo,
  logoSize,
  image,
  bgColor,
  testimonial,
  firstWord,
  secondWord,
  thirdWord,
  specialPadding,
  resultsText,
  author,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleVideoLoaded = () => {
    isMobile ? setIsVideoLoaded(false) : setIsVideoLoaded(true);
  };

  return (
    <Flex
      w="100%"
      justify="space-between"
      gap="1.5rem"
      mb="1.5rem"
      maxH={["100%", "100%", "500px"]}
      direction={["column", "column", "row"]}
    >
      <Box
        w={["100%", "100%", "70%"]}
        position="relative"
        borderRadius="1.5rem"
        overflow="hidden"
        role="group"
      >
        <Image
          src={image}
          w="100%"
          h="100%"
          objectFit="cover"
          minH={["500px", "500px", "initial"]}
          display={isVideoLoaded ? "none" : "block"}
          transition="all 0.3s"
          _groupHover={{
            transform: isMobile ? "scale(1.00)" : "scale(1.05)",
          }}
        />

        <Box
          as="video"
          src={video}
          autoPlay
          loop
          muted
          playsInline
          objectFit="cover"
          w="100%"
          h="100%"
          onCanPlayThrough={handleVideoLoaded}
          display={isVideoLoaded ? "block" : "none"}
          transition="all 0.3s"
          _groupHover={{
            transform: isMobile ? "scale(1.00)" : "scale(1.05)",
          }}
        />

        <Box
          position="absolute"
          top="0"
          right="0"
          bg={white}
          p={specialPadding}
          borderBottomLeftRadius="1rem"
          zIndex={2}
          _before={{
            right: "100%",
            transform: "rotate(-90deg)",
            content: '""',
            position: "absolute",
            top: 0,
            width: "1em",
            height: "1em",
            background:
              "radial-gradient(circle at 0 0, transparent 1em, #fff 0)",
          }}
        >
          <Image
            src={logo}
            alt="Domes"
            h={`${logoSize} !important`}
            w={`auto !important`}
            className="case-logo"
          />
        </Box>
        <Box position="absolute" bottom="1rem" left="1rem" zIndex={2}>
          <Image src={author} h={isMobile ? "2rem" : "3rem"} />
          {/* <Text color="white" fontSize="1.125rem" fontWeight="400">
            {name}
          </Text>
          <Text color="white" fontWeight="500" fontSize="1.125rem">
            {location}
          </Text> */}
        </Box>

        <Box position="absolute" bottom="1rem" right="1rem" zIndex={2}>
          <IconButton
            size="lg"
            bg={white}
            borderRadius="10rem"
            //   border={`1px solid ${textBlack}`}
            _groupHover={{
              bg: "#1e50ff",
              color: white,
              border: `1px solid #1e50ff`,
              cursor: "pointer",
            }}
          >
            <Icon as={FaArrowRightLong} fontWeight="300" fontSize="0.75rem" />
          </IconButton>
        </Box>

        <Box
          bg="rgba(0,0,0,0.2)"
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          display={["block", "block", "none"]}
          zIndex={1}
        />

        <Box
          position="absolute"
          top="0"
          left="0"
          bg="transparent"
          // top="50%"
          // left="50%"
          // transform="translate(-50%, -50%)"
          color="white"
          textAlign="left"
          p={["8rem 1rem 0", "8rem 1rem 0", "1rem"]}
          zIndex={2}
        >
          <Text
            fontSize={["1.875rem", "1.875rem", "3rem"]}
            fontWeight="400"
            lineHeight="1.2"
            mr={["0%", "0%", "20%"]}
          >
            {testimonial}
          </Text>
        </Box>
      </Box>

      <Flex
        w={["100%", "100%", "30%"]}
        bg={bgColor}
        borderRadius="1.5rem"
        direction="column"
        align="flex-start"
        justify="space-between"
        minH={["initial", "initial", "500px"]}
        p={["1rem 1rem", "1rem", "1rem"]}
        textAlign="left"
      >
        <Text fontSize={["4rem", "4rem", "6.25rem"]} fontWeight="400">
          <Text as="span" fontSize={["2rem", "2rem", "3rem"]} fontWeight="400">
            {firstWord}
          </Text>
          {secondWord}
          <Text as="span" fontSize={["2rem", "2rem", "3rem"]} fontWeight="400">
            {thirdWord}
          </Text>
        </Text>
        <Text
          fontSize="1.125rem"
          fontWeight="500"
          mr={["20%", "20%", "30%"]}
          mt={["1rem", "1rem", "0rem"]}
        >
          {resultsText}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CaseStudy;
